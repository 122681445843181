import React, { useEffect, useState } from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  const [playlists,setPlaylists] = useState([]);
  useEffect(() => {
     /* fetch("https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=UCh0QV8e-9aXQcjQOWPK7Gkg&key=AIzaSyAAjCXjIzmO1P4noWd6VaeQhNIqovwwidc")  */
     fetch("https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=UCsXVt0NUPUDV-v3NyCtVSCQ&key=AIzaSyAAjCXjIzmO1P4noWd6VaeQhNIqovwwidc")     
    .then(response => response.json())
        .then(data => {
            console.log(data);
            const response = data.items.map(item => {
                return {
                    title: item.snippet.localized.title,
                    playlistId: item.id,
                    imageUrl: item.snippet.thumbnails.standard.url
                };
               
                
            })
            setPlaylists(response);
            console.log("response");
                console.log(response);
        })
}, []);
  return (
    <>
    <div className='cards'>
      <h1 className='heading'>Check out our Tutorials!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          {playlists.map((playlist)=>{
            return(
              <ul className='cards__items'>
              <CardItem
                src={playlist.imageUrl}
                text={playlist.title}
                label='TECH DHABA'
                path={'/'+ playlist.playlistId}
              />
              </ul>

            )
          })}
          {/*  <ul className='cards__items'>
            <CardItem
              src='images/img-9.jpg'
              text='Explore the hidden waterfall deep inside the Amazon Jungle'
              label='Techdhaba'
              path='/courses'
            />
            </ul>  */}
            
        </div>
      </div>
    </div>
    </>
  );
}

export default Cards;
