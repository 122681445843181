import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';

export default function Courses() {
  return(
  <>
  {/* <h1 className='courses'>Courses</h1> */}
  <div className='courses'>
  <Cards></Cards>
  </div>
  <Footer/>
  </>)
}
