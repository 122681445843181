import React, { useEffect, useState } from 'react'
import { json, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './CourseStructure.css';
import ReactPlayer from 'react-player';

// https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=10&playlistId=PLB97yPrFwo5hpOay4v2nnDuUCZQMwyQzF&key=AIzaSyD6ks4j-QAUVZCIc10Vtxh3U45Bgg_Isvc
//https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=UCM-4HE0R3iLxKB7qaq1bskw&key=AIzaSyD6ks4j-QAUVZCIc10Vtxh3U45Bgg_Isvc


function CourseStructure(props) {
  const { PlaylistId } = useParams();
  /* const url = "https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=10&playlistId="+ PlaylistId+"&key=AIzaSyBjTQ6BsdrhQct2VbUSjuLZhT8lt9vOAA8" */
  const url = "https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=10&playlistId="+ PlaylistId+"&key=AIzaSyAAjCXjIzmO1P4noWd6VaeQhNIqovwwidc"
  const [courses, setCourse] = useState([]);

  const [counter, setCounter] = useState(0)
  const [vid, uid] = useState("");
  const [title, utitle] = useState("")

  const watched=(vid)=>{
    if(JSON.parse(localStorage.getItem("savedVid")))
    {
      
      if(JSON.parse(localStorage.getItem("savedVid")).includes(vid))
      return true
     else 
     return false;
    }
    else
    {
    return false
    }
  }



  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        const response = data.items.map(item => {
          return {
            title: item.snippet.title,
            vid: item.contentDetails.videoId
          }

        }
        )
        setCourse(response)
        uid(response[0].vid)
        utitle(response[0].title)
      })

  }, [])


  const renderVideo = () => {
    return (
      <>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css"></link>
        <section className='section1'>
         
        <h5 id="headingAbove">{title}</h5>
          <ReactPlayer
          className='react-player'
          url={`https://www.youtube.com/watch?v=${vid}`}
          width="100%"
          height="100%"
          controls={true}
          onEnded={()=>{
if(localStorage.getItem("savedVid"))
{
  var data = JSON.parse(localStorage.getItem("savedVid"))
  localStorage.setItem("savedVid",JSON.stringify([...data,vid]) )
}

else
{
  localStorage.setItem("savedVid",JSON.stringify([vid]))
}

          }}
        />
        <h5 id="headingBelow">{title}</h5> 
           

        </section>

      </>

    )

  }
  return (
    <div className='courseStructure'>
    { (courses.length > 0)?
    <div>
      <main className="container2">
        {renderVideo()}
        <section className="section2">
          <ul className='collection'>

            {
              courses.map((item, index) => {
                return (
                  <li key={item.vid} className={(counter === index) ? " collection-item selected-item" : "collection-item my-item"} onClick={() => {
                    uid(item.vid)
                    utitle(item.title)
                    setCounter(index)
                  }}>
                    
                    {item.title}
                     {
                    watched(item.vid) && <i className="tiny material-icons">check </i> 
                    }
                    </li>

                )

              })
            }

          </ul>

        </section>
      </main> 
    </div>
    :
    <h1>videos are loading</h1> 
}
    </div>

  );
}

export default CourseStructure;