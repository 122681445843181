import React, { useState } from "react";
import "./style.css";
import Course from './CourseApi'
import CourseCard from "./CourseCard";

const uniqueList = [
  ...new Set(
    Course.map((curElem) => {
      return curElem.category;
    })
  ),
  "All",
];

console.log(uniqueList);



const MainCourse = () => {
  const [courseData, setcourseData] = useState(Course);

  return (
    <>
    <div class="maincourse">
      <CourseCard courseData={courseData} />
      </div>
    </>
  );
};

export default MainCourse;
