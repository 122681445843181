const Course = [
  {
    id: 1,
    image: "images/courseImages/Angular.png",
    name: "Angular",
    category: "breakfast",
    price: "12₹",
    description:
      "I love Maggi realy oo yues  Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },

  {
    id: 2,
    image: "images/courseImages/React.png",
    name: "React",
    category: "evening",
    price: "20₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 3,
    image: "images/courseImages/Javascript.png",
    name: "Javascript",
    category: "breakfast",
    price: "10₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 4,
    image: "../images/courseImages/nodejs.png",
    name: "NodeJS",
    category: "evening",
    price: "10₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 5,
    image: "images/courseImages/C.png",
    name: "C",
    category: "lunch",
    price: "50₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 6,
    image: "../images/courseImages/C++.png",
    name: "C++",
    category: "evening",
    price: "80₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 7,
    image: "../images/courseImages/EmbeddedC.png",
    name: "EmbeddedC",
    category: "dinner",
    price: "180₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 8,
    image: "../images/courseImages/DataStructure.png",
    name: "DataStructure",
    category: "evening",
    price: "10₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 9,
    image: "../images/courseImages/DesignPattern.png",
    name: "DesignPattern",
    category: "evening",
    price: "10₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 10,
    image: "../images/courseImages/Java.png",
    name: "Java",
    category: "dinner",
    price: "60₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 11,
    image: "../images/courseImages/CSharp.png",
    name: "C#",
    category: "lunch",
    price: "60₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
  {
    id: 12,
    image: "../images/courseImages/dotnetcore.png",
    name: "DotNetCore",
    category: "evening",
    price: "10₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },

  {
    id: 13,
    image: "../images/courseImages/LINQ.png",
    name: "LINQ",
    category: "evening",
    price: "10₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },

  {
    id: 14,
    image: "../images/courseImages/EntityFrameworkCore.png",
    name: "EntityFrameworkCore",
    category: "evening",
    price: "10₹",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
  },
];

export default Course;
