// Import necessary libraries
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slide1 from "../images/slide1.png";
import slide2 from "../images/slide2.png";
import slide3 from "../images/slide3.png";
import slide4 from "../images/slide4.png";
import slide5 from "../images/slide5.png";
import './HomePageSlider.css'; // Create a CSS file for styling if needed

// Sample slide data
const slides = [
  {
    title: 'Empowering Minds with Tech Education',
    description: '',
    imageUrl: slide1,
    
  },
  
  {
    title: 'Immerse yourself in practical projects',
    description: '',
    imageUrl: slide2,
    
  },
  {
    title: 'Real-world scenarios',
    description: '',
    imageUrl: slide3,
  },
  {
    title: 'Gaining valuable skills.',
    description: '',
    imageUrl: slide4,
  },
  {
    title: 'Simplifying Complexity',
    description: '',
    imageUrl: slide5,
  }
  // Add more slides as needed
];

const HomepageSlider = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000, // Adjust the autoplay speed as needed (in milliseconds)
  };

  return (
    <div className="homepage-slider">
      <Slider {...sliderSettings}>
        {slides.map((slide, index) => (
          <div key={index} className="slider-item">
            <div className="slider-content1">
              <h6 >{slide.title}</h6>
            </div>
            <img src={slide.imageUrl} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomepageSlider;
