import React from "react";
import { Container, Row, Col } from "reactstrap";
//import heroImg from "../images/hero-img1.png";
import "./hero-section.css";
import { useNavigate } from "react-router-dom";
import HomepageSlider from "./HomePageSlider";

const HeroSection = () => {
  let navigate = useNavigate();
  return (
    <section className="sec1">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h2 className="mb-4 hero__title">
              Simplifying Complexity 
              </h2>
        
              <p className="mb-5"> 
              Whether you're a beginner or seasoned professional, our programs cater to all levels, offering a seamless journey towards technical proficiency. 
              <br></br><br></br>Join us in simplifying the intricate, empowering you to navigate the tech landscape with confidence and competence.
              </p>
            </div>
            <div>
              <button className="btn-custom" onClick={()=>{navigate('/maincourse')}}>Courses</button>
            </div>
          </Col>

          <Col lg="6" md="6">
            {/* <img src={heroImg} alt="" className="w-100 hero__img" /> */}
            <HomepageSlider />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
