import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      
     
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <a to='/' className='social-logo'>
              TechDhaba
              <i className='fas fa-sd-card' />
            </a>
          </div>
         
          <div className='social-icons'>
            <a
              className='social-icon-link facebook'
              href="https://m.facebook.com/techdhaba/"
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </a>
            <a
              className='social-icon-link instagram'
              href="https://instagram.com/_techdhaba?igshid=MzRlODBiNWFlZA=="
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </a>
            <a
              className='social-icon-link youtube'
              href='https://www.youtube.com/channel/UCsXVt0NUPUDV-v3NyCtVSCQ'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </a>
            <a
              className='social-icon-link twitter'
              href="https://twitter.com/techdhaba"
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </a>
            <a
              className='social-icon-link twitter'
              href='https://in.linkedin.com/in/techdhaba?original_referer=https%3A%2F%2Fwww.google.com%2F'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </a>
          </div>

          <small className='website-rights'>TechDhaba © 2023</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
