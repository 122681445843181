import React from 'react'
import HeroSection from '../HeroSection'
import '../../App.css'
import Cards from '../Cards';
import Footer from '../Footer';
import MainCourse from '../Course/MainCourse';
import Compiler from '../Compiler/Compiler';

function Home() {
  return (
    <>
      <HeroSection/>
      <MainCourse/> 
      <Cards />
      
      <Footer />
    </>
  )
}

export default Home
