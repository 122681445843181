import React from 'react';
import '../pages/Aboutus.css'
import Footer from '../Footer';

export default function Products() {
  return (<div>
    <div className="body">
  <header>
    <h1>About Us - Tech Dhaba</h1>
  </header>

  <div class="container1">
    <div class="card">
      <h2 class="card-title">Welcome to Our Educational Technical Portfolio</h2>
      <p class="card-description">We are a dedicated team of educators and technologists passionate about leveraging technology to enhance the learning experience. Our goal is to provide innovative educational solutions that empower students, teachers, and institutions to reach their full potential.</p>
    </div>

    <div class="card">
      <h2 class="card-title">What We Offer</h2>
      <p class="card-description">Our portfolio showcases a range of educational projects and initiatives that we have developed and implemented. These projects cover various disciplines and target different educational levels, from primary schools to higher education.</p>
    </div>

    <div class="card">
      <h2 class="card-title">Our Expertise</h2>
      <p class="card-description">Our team comprises highly skilled professionals with expertise in educational technology, curriculum development, instructional design, and software development. We combine pedagogical knowledge with technical prowess to create effective and engaging learning experiences.</p>
    </div>

    <div class="card">
      <h2 class="card-title">Our Approach</h2>
      <p class="card-description">At Educational Technical Portfolio, we follow a student-centered approach that focuses on individualized learning, interactive content, and real-world applications. We believe in the power of technology to personalize education and foster critical thinking, creativity, and collaboration.</p>
    </div>

    <div class="card">
      <h2 class="card-title">Meet Our Team</h2>
      <p class="card-description">We have a diverse team of educators and technologists who bring a wealth of knowledge and experience to our projects. From subject matter experts to software engineers, our team collaborates to create cutting-edge educational solutions.</p>
    </div>

    {/* <div class="image-container">
      <img src="your-image.jpg" alt="Team Photo">
    </div> */}

    <div class="card">
      <h2 class="card-title">Contact Us</h2>
      <p class="card-description">If you have any questions, partnership inquiries, or would like to discuss a potential collaboration, we would love to hear from you. Please feel free to reach out to us through the contact number 7982039479.</p>
    </div>
  </div>
</div>
<Footer />

</div>)
}
