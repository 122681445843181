import './App.css';
import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/pages/Home';
import Courses from './components/pages/Courses';
import Aboutus from './components/pages/Aboutus';
import YouTubeChannel from './components/pages/YouTubeChannel';
import CourseStructure from './components/CourseStructure';
import Compiler from './components/Compiler/Compiler';
//import Cards from './components/Cards';
import MainCourse from './components/Course/MainCourse';

function App() {
  return (
    <> 
     <Router>
    <Navbar></Navbar>
    <Routes>
    <Route exact path="/" element={<Home />} />
    <Route path="/courses" element={<Courses />} />
    <Route path="/aboutus" element={<Aboutus />} />
    <Route path="/youtubechannel" element={<YouTubeChannel />} />
    <Route path="/:PlaylistId" element={<CourseStructure />} />
    <Route path="/compiler" element={<Compiler />} />
    <Route path="/maincourse" element={<MainCourse />} />

    </Routes>

    </Router> 




    </>
  )
}

export default App;
